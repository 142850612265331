/* eslint-disable */
import apiClient from "@/services/axios";
import store from "store";
import Vue from "vue";

export async function login(userName, password) {
  return apiClient
    .post("/users/login", {
      userName: userName,
      password: password,
    })
    .then((response) => {
      if (response) {
        const { token } = response.data;
        if (token) {
          store.set("accessToken", token);
        }
        const { info } = response.data;
        return info;
      }
    })
    .catch((err) => {
      const { data } = err.response;
      Vue.prototype.$notification.error({
        message: "Échec de la connexion",
        description: data.error.message ? data.error.message : "",
      });
      return false;
    });
}

export async function register(email, password, name) {
  return apiClient
    .post("/auth/register", {
      email,
      password,
      name,
    })
    .then((response) => {
      if (response) {
        const { accessToken } = response.data;
        if (accessToken) {
          store.set("accessToken", accessToken);
        }
        return response.data;
      }
      return false;
    });
}

export async function currentAccount() {
  return apiClient
    .post("/users")
    .then((response) => {
      return true
    })
    .catch((err) => {
      const { data } = err.response;
      Vue.prototype.$notification.error({
        message: "Échec de la connexion",
        description: data.error.message ? data.error.message : "",
      });
      return false;
    });
}

export async function logout() {
  try {
    store.remove("accessToken"); //app.settings.activeBuilding
    store.remove("app.settings.activeSchoolarYear");
    store.remove("DB");
    store.remove("app.settings.activeBuilding");
    store.remove("app.menu.selectedKeys");
    store.remove("app.topbar.favs");
    store.remove("app.settings.activeSchoolarYear");
    localStorage.clear();
    return true;
  } catch {
    return false;
  }
}
