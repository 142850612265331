<template>
  <div id="app">
    <localization></localization>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from "vuex";
import Localization from "@/localization";
import apiClient from "@/services/axios";
import Amiri from "raw-loader!./assets/fonts/Amiri.txt";
import pt_bold_heading from "raw-loader!./assets/fonts/pt_bold_heading.txt";
import noto from "raw-loader!./assets/fonts/NotoSansArabic-Bold.txt";
import amiri_bold from "raw-loader!./assets/fonts/Amiri-Bold.txt";

import JsPDF from "jspdf";

export default {
  name: "app",
  components: { Localization },
  computed: {
    ...mapState(["settings"]),
    ...mapState("user", ["authorized"]),
    nextRoute() {
      return this.$route.query.redirect || "/";
    },
    currentRoute() {
      return this.$route.path;
    },
  },
  created() {
    if (this.settings.base_url) {
      console.info(
        "setting up base URL :" + this.settings.base_url + "/api/web"
      );
      console.info("setting DB :" + this.settings.activeBuilding);
      apiClient.defaults.baseURL = this.settings.base_url + "/api/web";
      apiClient.defaults.headers.db = this.settings.activeBuilding;
    }
    //set Amiri font for unicode carrecters
    var callAddFont = function () {
      this.addFileToVFS("Amiri-normal.ttf", Amiri);
      this.addFont("Amiri-normal.ttf", "Amiri", "normal");
      this.addFileToVFS("pt_bold_heading.ttf", pt_bold_heading);
      this.addFont("pt_bold_heading.ttf", "PT Bold Heading", "Bold");
      this.addFileToVFS("NotoSansArabic-Bold.ttf", noto);
      this.addFont("NotoSansArabic-Bold.ttf", "Noto Sans Arabic", "Bold");
      this.addFileToVFS("amiri_bold.ttf", amiri_bold);
      this.addFont("amiri_bold.ttf", "Amiri", "Bold");
    };
    JsPDF.API.events.push(["addFonts", callAddFont]);
  },
  mounted() {
    this.$store.commit("settings/SET_PRIMARY_COLOR", {
      color: this.settings.primaryColor,
    });
    this.$store.commit("settings/SET_THEME", { theme: this.settings.theme });
  },
  sockets: {
    connect: function () {},
  },
  watch: {
    "$store.state.settings.theme"(theme) {
      this.$store.commit("settings/SET_THEME", { theme });
    },
    authorized(authorized) {
      if (authorized && this.currentRoute === "/auth/login") {
        this.$router.replace(this.nextRoute);
      }
    },
    $route(to, from) {
      const query = Object.assign({}, to.query);
      this.$store.commit("settings/SETUP_URL_SETTINGS", query);
    },
  },
};
</script>
