<template>
  <div>
    <cui-sidebar />
    <a-select show-search placeholder="Etablissement" :autoFocus="true" option-filter-prop="children"
      style="width: 250%" class="" :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur"
      @change="handleChange" :default-value="settings.activeBuilding" v-model="settings.activeBuilding">
      <a-select-option :value="building.db" v-for="building in settings.image" :key="building.db">
        {{ building.name }}
      </a-select-option>
    </a-select>
  </div>
</template>
<script>
/* eslint-disable */
import store from "store";
import { mapState } from "vuex";
import CuiSidebar from "@/components/cleanui/layout/Sidebar";
import router from "@/router";
import apiClient from "@/services/axios";
export default {
  name: "AuthLayout",
  components: { CuiSidebar },
  computed: mapState(["settings", "user"]),
  methods: {
    async handleChange(value) {
      let buidlingId = this.user.building.find((e) => {
        return e.dbName === value;
      });
      await store.set("DB", value);
      await this.$store.commit("settings/CHANGE_SETTING", {
        setting: "activeBuilding",
        value: value,
      });
      await this.$store.commit("settings/CHANGE_SETTING", {
        setting: "activeBuildingId",
        value: buidlingId._id,
      });
      if (buidlingId.base_url) {
        await this.$store.commit("settings/CHANGE_SETTING", {
          setting: "base_url",
          value: buidlingId.base_url,
        });
        apiClient.defaults.baseURL = buidlingId.base_url + "/api/web";
      } else {
        await this.$store.commit("settings/CHANGE_SETTING", {
          setting: "base_url",
          value: process.env.VUE_APP_API_URL,
        });
        apiClient.defaults.baseURL = process.env.VUE_APP_API_URL + "/api/web";
      }

      await apiClient
        .post("/schoolaryears/filter", {
          query: { $query: {}, $orderby: { end: -1 } },
        })
        .then(async (res) => {
          const data = res.data.map((sc) => {
            return {
              ...sc,
              name:
                new Date(sc["start"]).getFullYear() +
                "-" +
                new Date(sc["end"]).getFullYear(),
            };
          });
          await this.$store.commit("settings/CHANGE_SETTING", {
            setting: "activeSchoolarYear",
            value: data[0]._id,
          });
          await this.$store.commit("settings/CHANGE_SETTING", {
            setting: "schoolarYears",
            value: data,
          });
          store.set("schoolarYears", data);
          store.set("SchoolarYear", data[0]._id);
          store.set("activeSchoolarYear", data[0]._id);
          router.push("/");
        });
      window.location.reload();
    },
    handleBlur() { },
    handleFocus() { },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
